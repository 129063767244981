<script>

const _filterUndefined = (data) => {
  const type = typeof data
  if (type !== 'undefined') {
    if (data instanceof Array) {
      return data.map(_filterUndefined).filter(a => typeof a !== 'undefined')
    } else if (type === 'object') {
      let newData = {}
      Object.keys(data || {}).forEach((k, i) => {
        const tmp = _filterUndefined(data[k])
        if (tmp.length) newData[k] = tmp
      })
      return newData
    }
    return data
  }
}

export default {
  name: 'FormCampaignMixins',
  data: () => ({
    loading: 0,
    selected: 0,
    fields: [
      'title',
      'start_date',
      'end_date',
      'channel_categories',
      'ga4_params',
    ],
    // database values
    loaded_values: undefined,
    // default values
    default_values: {
      title: undefined,
      start_date: undefined,
      end_date: undefined,
      channel_categories: [],
      ga4_params: {},
    },
    // current values
    values: {
      title: undefined,
      start_date: undefined,
      end_date: undefined,
      channel_categories: [],
      ga4_params: {}
    }
  }),
  props: {
    ids: {
      type: [Array, Boolean],
      default: () => ([])
    },
    props: {
      type: [Object, Boolean],
      default: () => ({})
    }
  },
  watch: {
    ids (n,o) {
      if (n && n.length > 0) {
        if (n[this.selected].id !== this.id) {
          this.selected = 0
          this.loadItem()
        }
      }
    }
  },
  computed: {
    canPrev () {
      return this.selected > 0 && this.ids.length > 0
    },
    canNext () {
      return this.selected < this.ids.length-1 && this.ids.length > 0
    },
    id () {
      return this.loaded_values ? this.loaded_values.id : null
    }
  },
  created () {
    if (this.ids.length > 0) {
      this.loadItem()
    } else {
      this.resetItem()
    }
  },
  methods: {

    // TODO : _filterUndefined should appear in all mixins
    // apply changes from another data (child component)
    handleInput (values) {
      values = _filterUndefined(this.$clone(values))
      // console.log(1,values)
      for (const field of this.fields) {
        if (typeof values[field] !== 'undefined') {
          if (
            values[field] instanceof Object
            && !(values[field] instanceof Array)
            && Object.keys(values[field]).length === 0
          ) {
            this.values[field] = {}
          } else {
            this.values[field] = values[field]
          }
        }
      }
      // console.log(2,this.values.custom)
    },


    // init and reset values
    resetItem () {
      const values = this.$clone(this.id ? this.loaded_values : this.default_values)
      for (const field of this.fields) {
        if (typeof values[field] !== 'undefined') this.values[field] = values[field]
      }
    },


    // load prev item on the fly
    prevItem () {
      if (this.canPrev) {
        this.selected--
        return this.loadItem()
      }
    },


    // load next item on the fly
    nextItem () {
      if (this.canNext) {
        this.selected++
        return this.loadItem()
      }
    },


    // load item
    loadItem () {
      this.loading = true
      const query = {where: {id: this.ids[this.selected]}}
      return this.$services.api_programmatic.campaign.search(query)
        .then(response => {
          if (response.data && response.data[0]) {
            this.loaded_values = this.$clone(response.data[0])
          }
          return response
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.resetItem()
          this.loading = false
        })

    },


    // save item
    saveItem () {
      this.loading = true
      if (!this.id) {
        const values = {}
        for (const field of this.fields) {
          if (typeof this.values[field] !== 'undefined') values[field] = this.values[field]
        }
        return this.$services.api_programmatic.campaign.create({values})
          .then(response => {
            if (response.data) {
              this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
              this.$emit('create', response.data[1])
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        const values = this.$compare(this.loaded_values, this.values, this.fields)
        if (Object.keys(values).length > 0) {
          return this.$services.api_programmatic.campaign.update({values, where: {id: this.id}})
            .then(response => {
              if (response.data) {
                this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
                this.$emit('update', this.id)
              }
            })
            .catch(e => {
              this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      }
    },


    // remove item
    removeItem () {
      if (this.id) {
        this.loading = true
        return this.$services.api_programmatic.campaign.update({values: {status: 0}, where: {id: this.id}})
          .then(response => {
            if (response.data) {
              this.$emit('remove', this.id)
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        console.warning('You cannot remove element without its id.')
      }
    },


    // refresh
    refreshItem () {
      if (this.id) {
        this.loading = true
        return this.$services.api_programmatic.campaign.refresh({where: {id: this.id}})
          .then(response => {
            if (response.data) {
              this.$emit('update', this.id)
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },



    cancelRefresh () {
      if (this.id) {
        this.loading = true
        return this.$services.api_programmatic.campaign.update({where: {id: this.id}, values: {need_refresh: null, status_type:null, status_code: null, status_message: null}})
          .then(response => {
            this.$emit('update', this.id)
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 3000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },


  }
}
</script>
